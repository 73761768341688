import { zGetAutoEmailCampaignsStatsTrpcInput } from '@chatai/admin-backend/src/router/autoEmailCampaigns/getAutoEmailCampaignsStats/input'
import { canAnalyzeEmails } from '@chatai/admin-backend/src/utils/can'
import { format, subDays } from 'date-fns'
import { useState } from 'react'
import { Alert } from '../../../components/Alert'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetAutoEmailCampaignsStatsTrpcInput.pick({
  dateFrom: true,
  dateTo: true,
  email: true,
  userSn: true,
})
export const AutoEmailCampaignsPage = withPageWrapper({
  title: 'Auto Email Campaigns',
  checkAccess: ({ ctx }) => canAnalyzeEmails(ctx.me),
})(() => {
  const [initialDateFrom] = useState(() => {
    return format(subDays(new Date(), 7), 'dd.MM.yyyy')
  })
  const [initialDateTo] = useState(() => {
    return format(new Date(), 'dd.MM.yyyy')
  })
  const filterForm = useForm({
    initialValues: {
      dateFrom: initialDateFrom as never,
      dateTo: initialDateTo as never,
      email: '',
      userSn: '' as never,
    },
    validationSchema,
  })
  const filter = (() => {
    const maybeFilter = {
      dateFrom: filterForm.formik.values.dateFrom,
      dateTo: filterForm.formik.values.dateTo,
      email: filterForm.formik.values.email,
      userSn: filterForm.formik.values.userSn,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return parseResult.success ? maybeFilter : {}
  })()
  const qr = trpc.getAutoEmailCampaignsStats.useQuery(filter)

  return (
    <Segment title="Auto Email Campaigns">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={filterForm.formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={filterForm.formik} />
          <Input maxWidth={'100%'} label="Email" name="email" formik={filterForm.formik} />
          <Input maxWidth={'100%'} label="User SN" name="userSn" formik={filterForm.formik} />
        </FormItems>
      </div>
      {(() => {
        if (qr.error) {
          return <Alert color="red">{qr.error.message}</Alert>
        }
        if (!qr.data) {
          return <Loader type="section" />
        }
        const autoEmailCampaignsStats = qr.data.autoEmailCampaignsStats
        return (
          <table className={css.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Total</th>
                <th>Delivered</th>
                <th>Opened</th>
                <th>Clicked</th>
                <th>Spammed</th>
                <th>Invalid Email</th>
                <th>Blocked</th>
                <th>Unsubscribed</th>
                <th>Opened %</th>
              </tr>
            </thead>
            <tbody>
              {autoEmailCampaignsStats.map((acs, i) => (
                <tr key={i} className={acs.name === '' ? css.bold : undefined}>
                  <td>{acs.name}</td>
                  <td>{acs.total}</td>
                  <td>{acs.delivered}</td>
                  <td>{acs.opened}</td>
                  <td>{acs.clicked}</td>
                  <td>{acs.spammed}</td>
                  <td>{acs.invalidEmail}</td>
                  <td>{acs.blocked}</td>
                  <td>{acs.unsubscribed}</td>
                  <td>{acs.conversion.toFixed(2) + '%'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      })()}
    </Segment>
  )
})
