import { zStringRequired } from '@chatai/shared/src/zod'
import { z } from 'zod'

export const zLandingRedirectConfigInput = z.object({
  onelinkUrl: zStringRequired.url(),
  landingUrl: zStringRequired.url(),
  alias: zStringRequired,
  searchParamsMatch: zStringRequired,
  description: zStringRequired,
})
