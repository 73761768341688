import { zUpdateAppConfigTrpcInput } from '@chatai/admin-backend/src/router/appConfig/updateAppConfig/input'
import { canManageAppConfig } from '@chatai/admin-backend/src/utils/can'
import { z } from 'zod'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

export const AppConfigPage = withPageWrapper({
  authorizedOnly: true,
  useQuery: () => {
    return trpc.getAppConfig.useQuery()
  },
  setProps: ({ queryResult }) => ({
    appConfig: queryResult.data.appConfig,
  }),
  checkAccess: ({ ctx }) => canManageAppConfig(ctx.me),
  title: 'App Config',
})(({ appConfig }) => {
  const updateAppConfig = trpc.updateAppConfig.useMutation()
  const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      openaiApiKeys: appConfig.openaiApiKeys.join('\n'),
      freePlanMonthlyMessagesLimits: appConfig.freePlanMonthlyMessagesLimits.join('\n'),
      paidPlanMonthlyDollarsLimit: appConfig.paidPlanMonthlyDollarsLimit.toString(),
      paidPlanMonthlyDollarsPrelimit: appConfig.paidPlanMonthlyDollarsPrelimit.toString(),
      aiEndpointsIsDown: appConfig.aiEndpointsIsDown,
    },
    validationSchema: z.object({
      openaiApiKeys: z.string().min(1),
      freePlanMonthlyMessagesLimits: z.string().min(1),
      paidPlanMonthlyDollarsLimit: z.string().min(1),
      paidPlanMonthlyDollarsPrelimit: z.string().min(1),
      aiEndpointsIsDown: z.boolean(),
    }),
    onSubmit: async (values) => {
      const openaiApiKeys = values.openaiApiKeys
        .split('\n')
        .map((x) => x.trim())
        .filter((x) => x)
      const freePlanMonthlyMessagesLimits = values.freePlanMonthlyMessagesLimits
        .split('\n')
        .map((x) => +x.trim())
        .filter((x) => x)
      const paidPlanMonthlyDollarsLimit = +values.paidPlanMonthlyDollarsLimit.trim()
      const paidPlanMonthlyDollarsPrelimit = +values.paidPlanMonthlyDollarsPrelimit.trim()
      const input = zUpdateAppConfigTrpcInput.parse({
        openaiApiKeys,
        freePlanMonthlyMessagesLimits,
        paidPlanMonthlyDollarsLimit,
        paidPlanMonthlyDollarsPrelimit,
        aiEndpointsIsDown: values.aiEndpointsIsDown,
      })
      const result = await updateAppConfig.mutateAsync(input)
      trpcUtils.getAppConfig.setData(undefined, result)
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'App config updated',
  })

  return (
    <Segment title="App Config">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Checkbox label="AI Endpoints Is Down" name="aiEndpointsIsDown" formik={formik} />
          <Textarea label="OpenAI API Keys" name="openaiApiKeys" formik={formik} />
          <Textarea label="Free Plan Monthly Messages Limits" name="freePlanMonthlyMessagesLimits" formik={formik} />
          <Input label="Paid Plan Monthly Dollars Limit" name="paidPlanMonthlyDollarsLimit" formik={formik} />
          <Input label="Paid Plan Monthly Dollars Prelimit" name="paidPlanMonthlyDollarsPrelimit" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update App Config</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
