/* eslint-disable @typescript-eslint/unbound-method */
import type { Admin, AdminPermission, AdminRole } from '@chatai/prisma/client'
import _ from 'lodash'
import { z } from 'zod'

type MaybeAdmin = Pick<Admin, 'permissions' | 'id' | 'role' | 'bannedAt'> | null

export const allPermissions: AdminPermission[] = [
  'all',
  'viewUsers',
  'viewUnsubscriptions',
  'viewAdminActionLogs',
  'viewConversions',
  'syncPrompts',
  'manageUsers',
  'manageAdmins',
  'sendPushes',
  'analyzeChats',
  'manageAppConfig',
  'manageLandingRedirectConfigs',
  'manageUpgradesPosts',
  'manageNewsPosts',
  'manageAssistants',
  'manageAutoCampaigns',
  'clearAdapty',
  'manageTickets',
  'analyzePushes',
  'analyzePrompts',
]

export const allRoles: AdminRole[] = ['superAdmin', 'manager', 'analyst', 'mutant']

export const availablePermissions = allPermissions.filter((permission) => permission !== 'all')

export const availableRoles = ['manager', 'analyst'] as const

export const zAvailableRoles = z.enum(availableRoles)

export const humanReadablePermissions = Object.fromEntries(
  allPermissions.map((permission: AdminPermission) => [
    permission,
    _.snakeCase(permission).split('_').map(_.capitalize).join(' '),
  ])
) as Record<AdminPermission, string>

export const toHumanReadablePermission = (permission: AdminPermission) => humanReadablePermissions[permission]

export const humanReadableRoles = {
  superAdmin: 'Super Admin',
  manager: 'Manager',
  analyst: 'Analyst',
  mutant: 'Mutant',
}

export const toHumanReadableRole = (role: AdminRole) => humanReadableRoles[role]

const rolesWithPermissions: Record<AdminRole, AdminPermission[]> = {
  superAdmin: ['all'],
  manager: [
    'viewUsers',
    'viewUnsubscriptions',
    'viewAdminActionLogs',
    'viewConversions',
    'syncPrompts',
    'analyzePrompts',
    'manageUsers',
    'sendPushes',
    'analyzePushes',
    'analyzeEmails',
    'analyzeChats',
    'manageAppConfig',
    'manageUpgradesPosts',
    'manageNewsPosts',
    'manageAssistants',
    'manageAutoCampaigns',
    'clearAdapty',
    'manageTickets',
    'manageLandingRedirectConfigs',
  ],
  analyst: [
    'viewUsers',
    'viewUnsubscriptions',
    'viewConversions',
    'analyzeChats',
    'analyzePushes',
    'analyzePrompts',
    'analyzeEmails',
    'manageTickets',
  ],
  mutant: [] as AdminPermission[],
}

export const availableRolesOptions = availableRoles.map((role) => ({
  value: role,
  label: humanReadableRoles[role] + ': ' + rolesWithPermissions[role].map(toHumanReadablePermission).join(', '),
}))

export const hasPermission = (admin: MaybeAdmin, permission: AdminPermission) => {
  if (admin?.bannedAt) {
    return false
  }
  return admin?.permissions.includes(permission) || admin?.permissions.includes('all') || false
}

const getSuiatbaleRolesByPermission = (permission: AdminPermission) => {
  return Object.entries(rolesWithPermissions)
    .filter(([, rolePermissions]) => rolePermissions.includes(permission) || rolePermissions.includes('all'))
    .map(([role]) => role as AdminRole)
}

export const hasPermissionOrRole = (admin: MaybeAdmin, permission: AdminPermission) => {
  if (admin?.bannedAt) {
    return false
  }
  const suitableRoles = getSuiatbaleRolesByPermission(permission)
  if (admin?.role && suitableRoles.includes(admin?.role)) {
    return true
  }
  if (admin?.role !== 'mutant') {
    return false
  }
  return hasPermission(admin, permission)
}

export const canViewUsers = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'viewUsers')
}

export const canViewUnsubscriptions = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'viewUnsubscriptions')
}

export const canViewConversions = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'viewConversions')
}

export const canManageUsers = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageUsers')
}

export const canManageUpgradesPosts = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageUpgradesPosts')
}

export const canManageNewsPosts = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageNewsPosts')
}

export const canManageAssistants = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageAssistants')
}

export const canManageAppConfig = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageAppConfig')
}

export const canClearAdapty = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'clearAdapty')
}

export const canViewAdminActionLogs = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'viewAdminActionLogs')
}

export const canSyncPrompts = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'syncPrompts')
}

export const canSendPushes = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'sendPushes')
}

export const canAnalyzeChats = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'analyzeChats')
}

export const canManageTickets = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageTickets')
}

export const canManageAutoCampaigns = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageAutoCampaigns')
}

export const canAnalyzePushes = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'analyzePushes')
}

export const canAnalyzeEmails = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'analyzeEmails')
}

export const canAnalyzePrompts = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'analyzePrompts')
}

export const canManageLandingRedirectConfigs = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageLandingRedirectConfigs')
}

export const canManageAdmins = (admin: MaybeAdmin) => {
  return hasPermissionOrRole(admin, 'manageAdmins')
}
