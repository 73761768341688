import cn from 'classnames'
import { type HTMLAttributeAnchorTarget } from 'react'
import { Link } from 'react-router-dom'
import css from './index.module.scss'

type ButtonColor = 'red' | 'green' | 'pink'
export type ButtonProps = {
  children: React.ReactNode
  loading?: boolean
  color?: ButtonColor
  type?: 'button' | 'submit'
  disabled?: boolean
  onClick?: () => void
  style?: React.CSSProperties
  className?: string
}
export const Button = ({
  children,
  loading = false,
  color = 'green',
  type = 'submit',
  disabled,
  className,
  style,
  onClick,
}: ButtonProps) => {
  return (
    <button
      className={cn(className, {
        [css.button]: true,
        [css[`color-${color}`]]: true,
        [css.disabled]: disabled || loading,
        [css.loading]: loading,
      })}
      type={type}
      disabled={disabled || loading}
      style={style}
      onClick={onClick}
    >
      <span className={css.text}>{children}</span>
    </button>
  )
}

export const LinkButton = ({
  children,
  to,
  href,
  color = 'green',
  target,
}: {
  children: React.ReactNode
  to?: string
  href?: string
  color?: ButtonColor
  target?: HTMLAttributeAnchorTarget
}) => {
  const classNameHere = cn({ [css.button]: true, [css[`color-${color}`]]: true })
  const restProps = {
    target,
  }
  if (to) {
    return (
      <Link className={classNameHere} to={to} {...restProps}>
        {children}
      </Link>
    )
  }
  if (href) {
    return (
      <a className={classNameHere} href={href} {...restProps}>
        {children}
      </a>
    )
  }
  return null
}

export const Buttons = ({ children }: { children: React.ReactNode }) => {
  return <div className={css.buttons}>{children}</div>
}
