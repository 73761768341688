import { zGetConversionsStatsTrpcInput } from '@chatai/admin-backend/src/router/conversions/getConversionsStats/input'
import { canViewConversions } from '@chatai/admin-backend/src/utils/can'
import { format, subDays } from 'date-fns'
import { saveAs } from 'file-saver'
import trim from 'lodash/trim'
import { useRef } from 'react'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button/index.tsx'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea/index.tsx'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetConversionsStatsTrpcInput.pick({
  installDateFrom: true,
  installDateTo: true,
  purchaseDateFrom: true,
  purchaseDateTo: true,
  search: true,
  groupBy: true,
})
export const ConversionsStatsPage = withPageWrapper({
  title: 'Conversions Stats',
  checkAccess: ({ ctx }) => canViewConversions(ctx.me),
})(() => {
  const getConversionsStatsCsv = trpc.getConversionsStatsCsv.useMutation()
  const nowRef = useRef(new Date())
  const searchForm = useForm({
    initialValues: {
      installDateFrom: format(subDays(nowRef.current, 7), 'dd.MM.yyyy'),
      installDateTo: format(nowRef.current, 'dd.MM.yyyy'),
      purchaseDateFrom: '',
      purchaseDateTo: '',
      search: '',
      groupBy: '',
    },
    validationSchema,
  })
  const csvForm = useForm({
    onSubmit: async () => {
      const { base64 } = await getConversionsStatsCsv.mutateAsync({
        installDateFrom: searchForm.formik.values.installDateFrom?.toString(),
        installDateTo: searchForm.formik.values.installDateTo?.toString(),
        purchaseDateFrom: searchForm.formik.values.purchaseDateFrom?.toString(),
        purchaseDateTo: searchForm.formik.values.purchaseDateTo?.toString(),
        search: searchForm.formik.values.search,
        groupBy: searchForm.formik.values.groupBy,
      })
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'text/csv' })
      saveAs(blob, 'conversions_stats.csv')
    },
  })
  const filter = (() => {
    const maybeFilter = {
      search: searchForm.formik.values.search,
      groupBy: searchForm.formik.values.groupBy,
      installDateFrom: searchForm.formik.values.installDateFrom,
      installDateTo: searchForm.formik.values.installDateTo,
      purchaseDateFrom: searchForm.formik.values.purchaseDateFrom,
      purchaseDateTo: searchForm.formik.values.purchaseDateTo,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return (
      parseResult.success
        ? maybeFilter
        : {
            installDateFrom: format(subDays(nowRef.current, 7), 'dd.MM.yyyy'),
            installDateTo: format(nowRef.current, 'dd.MM.yyyy'),
            purchaseDateFrom: '',
            purchaseDateTo: '',
            search: '',
            groupBy: '',
          }
    ) as {
      groupBy: string
      search: string
      installDateFrom: string
      installDateTo: string
      purchaseDateFrom: string
      purchaseDateTo: string
    }
  })()

  const qr = trpc.getConversionsStats.useQuery({
    ...filter,
  })
  const groupByFields = ['type', ...(filter.groupBy?.split(',').map(trim).filter(Boolean) || [])]

  return (
    <Segment title="Conversions Stats">
      <div className={css.filter}>
        <FormItems>
          <Textarea
            maxWidth={'100%'}
            label="Search Params"
            name="search"
            formik={searchForm.formik}
            initialHeight={60}
            hint={
              <>
                mediaSource=FacebookAds
                <br />
                campaignId=~Reels
              </>
            }
          />
          <Input
            maxWidth={'100%'}
            label="Group By"
            name="groupBy"
            formik={searchForm.formik}
            hint="day, landingRedirectAlias, mediaSource, channel, campaign, campaignId, adset, adsetId, ad, adId, siteId, keywords, countryCode"
          />
          <Input maxWidth={'100%'} label="Install Date From" name="installDateFrom" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Install Date To" name="installDateTo" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Purchase Date From" name="purchaseDateFrom" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Purchase Date To" name="purchaseDateTo" formik={searchForm.formik} />
          {!qr.error && (
            <Button
              type="button"
              onClick={() => {
                csvForm.formik.handleSubmit()
              }}
            >
              Download CSV
            </Button>
          )}
        </FormItems>
      </div>
      {qr.error ? (
        <Alert color="red">{qr.error.message}</Alert>
      ) : !qr.data ? (
        <Loader type="section" />
      ) : (
        <table className={css.table}>
          <thead>
            <tr>
              <th colSpan={groupByFields.length + 12}>General</th>
              <th className={css.yellow} colSpan={9}>
                Weekly
              </th>
              <th className={css.purple} colSpan={3}>
                Yearly
              </th>
            </tr>
            <tr>
              {/* <th>Group By: {filter.groupBy || 'none'}</th> */}
              {groupByFields.map((field, i) => (
                <th key={i}>Group: {field || '—'}</th>
              ))}
              <th>Install</th>
              <th>First Open</th>
              <th>I→FO</th>
              <th>AD Spend</th>
              <th>Revenue</th>
              <th>ROAS 1</th>
              <th>ROAS 7</th>
              <th>Proceeds</th>
              <th>Refunds $</th>
              <th>Refunds N</th>
              {/* <th>Ads Cost</th> */}
              <th>I→P</th>
              <th>R per I</th>
              <th className={css.yellow}>Trial</th>
              <th className={css.yellow}>I→T</th>
              <th className={css.yellow}>Purchase After Trial</th>
              <th className={css.yellow}>T→P</th>
              <th className={css.yellow}>First Purchase</th>
              <th className={css.yellow}>Total Purchases</th>
              <th className={css.yellow}>Revenue</th>
              <th className={css.yellow}>Cancel Trial</th>
              <th className={css.yellow}>T→CT</th>
              <th className={css.purple}>First Purchase</th>
              <th className={css.purple}>Total Purchases</th>
              <th className={css.purple}>Revenue</th>
            </tr>
          </thead>
          <tbody>
            {qr.data.map((item, j) => {
              return (
                <tr key={j}>
                  {/* <td>{item.groupByValue}</td> */}
                  {item.groupByValues.map((v, i) => (
                    <td key={i}>{v || '—'}</td>
                  ))}
                  <td>{item.stats.install}</td>
                  <td>{item.stats.firstOpen}</td>
                  <td>{item.stats.installFirstOpen.toFixed(2) + '%'}</td>
                  <td>{item.stats.adSpend.toFixed(2) + '$'}</td>
                  <td>{item.stats.revenue.toFixed(2) + '$'}</td>
                  <td>{item.stats.roas1.toFixed(2) + '%'}</td>
                  <td>{item.stats.roas7.toFixed(2) + '%'}</td>
                  <td>{item.stats.proceeds.toFixed(2) + '$'}</td>
                  <td>{item.stats.refunds.toFixed(2) + '$'}</td>
                  <td>{item.stats.refundsCount}</td>
                  {/* <td>{item.stats.cost}</td> */}
                  <td>{item.stats.installPurchase.toFixed(2) + '%'}</td>
                  <td>{item.stats.revenuePerInstall.toFixed(2) + '$'}</td>
                  <td className={css.yellow}>{item.stats.startTrialWeek}</td>
                  <td className={css.yellow}>{item.stats.installStartTrialWeek.toFixed(2) + '%'}</td>
                  <td className={css.yellow}>{item.stats.purchaseAfterTrialWeek}</td>
                  <td className={css.yellow}>{item.stats.startTrialPurchaseWeek.toFixed(2) + '%'}</td>
                  <td className={css.yellow}>{item.stats.anyPurchaseWeek}</td>
                  <td className={css.yellow}>{item.stats.totalPurchaseWeek}</td>
                  <td className={css.yellow}>{item.stats.revenueWeek.toFixed(2) + '$'}</td>
                  <td className={css.yellow}>{item.stats.trialRenewalCancelledWeek}</td>
                  <td className={css.yellow}>{item.stats.cancelTrialOverStartTrialWeek.toFixed(2) + '%'}</td>
                  <td className={css.purple}>{item.stats.anyPurchaseYear}</td>
                  <td className={css.purple}>{item.stats.totalPurchaseYear}</td>
                  <td className={css.purple}>{item.stats.revenueYear.toFixed(2) + '$'}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </Segment>
  )
})
