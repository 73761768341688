import { zGetConversionsPredictionsTrpcInput } from '@chatai/admin-backend/src/router/conversions/getConversionsPredictions/input'
import { canViewConversions } from '@chatai/admin-backend/src/utils/can'
import { format, subDays } from 'date-fns'
import { saveAs } from 'file-saver'
import { useRef } from 'react'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button/index.tsx'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetConversionsPredictionsTrpcInput.pick({
  installDateFrom: true,
  installDateTo: true,
})
export const ConversionsPredictionsPage = withPageWrapper({
  title: 'Conversions Predictions',
  checkAccess: ({ ctx }) => canViewConversions(ctx.me),
})(() => {
  const getConversionsPredictionsCsv = trpc.getConversionsPredictionsCsv.useMutation()
  const nowRef = useRef(new Date())
  const searchForm = useForm({
    initialValues: {
      installDateFrom: format(subDays(nowRef.current, 14), 'dd.MM.yyyy') as never as Date,
      installDateTo: format(nowRef.current, 'dd.MM.yyyy') as never as Date,
    },
    validationSchema,
  })
  const csvForm = useForm({
    onSubmit: async () => {
      const { base64 } = await getConversionsPredictionsCsv.mutateAsync({
        installDateFrom: searchForm.formik.values.installDateFrom?.toString(),
        installDateTo: searchForm.formik.values.installDateTo?.toString(),
      })
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'text/csv' })
      saveAs(blob, 'conversions_predictions.csv')
    },
  })
  const filter = (() => {
    const maybeFilter = {
      installDateFrom: searchForm.formik.values.installDateFrom,
      installDateTo: searchForm.formik.values.installDateTo,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return (
      parseResult.success
        ? maybeFilter
        : {
            installDateFrom: format(subDays(nowRef.current, 7), 'dd.MM.yyyy'),
            installDateTo: format(nowRef.current, 'dd.MM.yyyy'),
          }
    ) as {
      installDateFrom: string
      installDateTo: string
    }
  })()

  const qr = trpc.getConversionsPredictions.useQuery({
    ...filter,
  })

  return (
    <Segment title="Conversions Predictions">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Install Date From" name="installDateFrom" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Install Date To" name="installDateTo" formik={searchForm.formik} />
          {!qr.error && (
            <Button
              type="button"
              onClick={() => {
                csvForm.formik.handleSubmit()
              }}
            >
              Download CSV
            </Button>
          )}
        </FormItems>
      </div>
      {qr.error ? (
        <Alert color="red">{qr.error.message}</Alert>
      ) : !qr.data ? (
        <Loader type="section" />
      ) : (
        <table className={css.table}>
          <thead>
            <tr>
              <th>Install Date</th>
              <th>Cohort Age</th>
              <th>Cost</th>
              <th>Trials W</th>
              <th>Subs W</th>
              <th>Subs Y</th>
              <th>Subs</th>
              <th>Income Real</th>
              <th>Income Predict 28D</th>
              <th>Income Predict 180D</th>
              <th>Income Predict 1Y</th>
              <th>Income Predict 3Y</th>
              <th>ROI Real</th>
              <th>ROI Predict 180D</th>
              <th>ROI Predict 1Y</th>
              <th>ROI Predict 3Y</th>
            </tr>
          </thead>
          <tbody>
            {qr.data.map((item, j) => {
              return (
                <tr key={j}>
                  <td>{format(new Date(item.installDate), 'dd.MM.yyyy')}</td>
                  <td>{item.cohortAge.toString()}</td>
                  <td>{item.cost.toFixed(2) + '$'}</td>
                  <td>{item.trialsW.toString()}</td>
                  <td>{item.subsW.toString()}</td>
                  <td>{item.subsY.toString()}</td>
                  <td>{item.subs.toString()}</td>
                  <td>{item.incomeReal.toFixed(2) + '$'}</td>
                  <td>{item.incomePredict28D.toFixed(2) + '$'}</td>
                  <td>{item.incomePredict180D.toFixed(2) + '$'}</td>
                  <td>{item.incomePredict1Y.toFixed(2) + '$'}</td>
                  <td>{item.incomePredict3Y.toFixed(2) + '$'}</td>
                  <td>{item.roiReal.toFixed(2) + '%'}</td>
                  <td>{item.roiPredict180D.toFixed(2) + '%'}</td>
                  <td>{item.roiPredict1Y.toFixed(2) + '%'}</td>
                  <td>{item.roiPredict3Y.toFixed(2) + '%'}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </Segment>
  )
})
